/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "input_colors_default": "bg-white active:bg-gray-100 shadow-black hover:shadow-black active:shadow-black focus:shadow-royal-blue-700 before:bg-royal-blue-200 after:border-black",
  "input_colors_alternative": "",
  "input_size_default": "h-5 w-5 p-1.25 shadow-inset-1 hover:shadow-inset-2 focus:shadow-inset-2 before:w-[170%] before:h-[170%] before:scale-0 hover:before:scale-0 active:before:scale-0 focus:before:scale-100 after:w-2.5 after:h-1.25 after:border-l-2 after:border-b-2 after:scale-0 checked:after:scale-100",
  "input_size_alternative": "",
  "input_variant_default": "appearance-none transition-all relative before:block before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:transition-all before:rounded-full before:-z-2 after:block after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-2/3 checked:after:-rotate-45 after:origin-center after:transition-all",
  "input_variant_alternative": "",
  "label_colors_default": "text-black",
  "label_colors_alternative": "",
  "label_size_default": "",
  "label_size_alternative": "",
  "label_variant_alternative": "",
  "caption_colors_default": "",
  "caption_size_default": "w-full",
  "caption_variant_default": "order-1",
  "colors_default": "",
  "colors_alternative": "",
  "size_default": "gap-x-2",
  "size_alternative": "",
  "variant_default": "flex items-center",
  "variant_alternative": "",
  "label_variant_default": "text-input-md"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Checkbox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;