import { GenericComponentFactory } from 'base/components/GenericComponent';
import { useClassName } from '@/styles/CheckoutFooter';

const GenericComponent = GenericComponentFactory({ useClassName });

const Footer = GenericComponent({ as: 'div', styles: { key: '' } });
const Title = GenericComponent({ as: 'h2', styles: { key: 'title' } });
const Background = GenericComponent({ as: 'picture', styles: { key: 'background' } });
const Content = GenericComponent({ as: 'div', styles: { key: 'content' } });
const PaymentGatewayLogo = GenericComponent({ as: 'div', styles: { key: 'paymentGatewayLogo' } });

export const CheckoutFooter = Object.assign(Footer, {
  Background,
  Title,
  Content,
  PaymentGatewayLogo,
});
