import { ClassNameProvider } from '@/styles/CheckoutHeader';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { CheckoutHeader } from './CheckoutHeader';

export interface StandaloneCheckoutHeaderProps extends StandaloneComponentProps<typeof CheckoutHeader> {
  background?: ReactNode;
  image?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
}

export const StandaloneCheckoutHeader: StandaloneComponent<StandaloneCheckoutHeaderProps> = ({
  background,
  image,
  title,
  description,
  options,
  ...props
}) => {
  const { $background, $content, ...$base } = options ?? {};
  const { $image, $title, $description } = $content ?? {};

  return (
    <ClassNameProvider value={$base}>
      <CheckoutHeader {...$base} {...props}>
        {background && <CheckoutHeader.Background {...$background}>{background}</CheckoutHeader.Background>}
        <CheckoutHeader.Content {...$content}>
          {image && <CheckoutHeader.Content.Image {...$image}>{image}</CheckoutHeader.Content.Image>}
          {title && <CheckoutHeader.Content.Title {...$title}>{title}</CheckoutHeader.Content.Title>}
          {description && (
            <CheckoutHeader.Content.Description {...$description}>{description}</CheckoutHeader.Content.Description>
          )}
        </CheckoutHeader.Content>
      </CheckoutHeader>
    </ClassNameProvider>
  );
};
