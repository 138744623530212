/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandalonePage404 as ResolvedStandalonePage404 } from 'sites/svenskdam/components/standalone/Page404';

export const Page404 = ResolvedStandalonePage404;

export type Page404Props = PropsFromComponent<typeof Page404>;

export const Page404With = (extras: DynamicStandaloneExtras): typeof Page404 => {
    return function Page404(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Page404');
        return <ResolvedStandalonePage404 {...mergeProps({ options: { theme } }, props)} />;
    }
}