import { Link } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import Head from 'next/head';
import { ReactNode } from 'react';
import { Page404, Page404Props } from './Page404';
import { Page404DescriptionProps } from './Page404.Description';
import { Page404HeadlineProps } from './Page404.Headline';

const StandalonePage404Defaults: {
  description: StandalonePage404Props['description'];
  headline: StandalonePage404Props['headline'];
  title: StandalonePage404Props['title'];
} = {
  description: (
    <>
      <p>Vi kunde tyvärr inte hitta sidan du letade efter.</p>
      <p>
        <Link href="/" content="Klicka här" /> för att komma tillbaka till startsidan eller välj något i menyn ovan.
      </p>
    </>
  ),
  headline: `OJDÅ, NU BLEV DET LITE TOKIGT (404)`,
  title: `Sidan saknas (404)`,
};

export interface StandalonePage404Props extends StandaloneComponentProps {
  description?: ReactNode;
  headline?: ReactNode;
  title?: string;
  options?: Page404Props & {
    $description?: Page404DescriptionProps;
    $headline?: Page404HeadlineProps;
  };
}

export const StandalonePage404: StandaloneComponent<StandalonePage404Props> = ({
  description,
  headline,
  title,
  options,
  ...props
}) => {
  const { $description: descriptionProps, $headline: headlineProps, ...baseProps } = options ?? {};

  return (
    <>
      <Head>
        <title>{title || StandalonePage404Defaults.title}</title>
      </Head>
      <Page404 {...baseProps} {...props}>
        <Page404.Headline {...headlineProps}>{headline || StandalonePage404Defaults.headline}</Page404.Headline>
        <Page404.Description {...descriptionProps}>
          {description || StandalonePage404Defaults.description}
        </Page404.Description>
      </Page404>
    </>
  );
};
