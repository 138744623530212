/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "description_colors_default": "text-black [&_a]:text-primary-600",
  "description_size_default": "text-body-md",
  "description_variant_default": "break-words",
  "headline_colors_default": "text-black",
  "headline_size_default": "text-headline-2xl",
  "headline_variant_default": "break-words",
  "colors_default": "",
  "size_default": "gap-6 my-9",
  "variant_default": "flex flex-col items-center text-center min-w-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Page404");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;