/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneKlarnaPayment as ResolvedStandaloneKlarnaPayment } from 'base/components/standalone/KlarnaPayment';

export const KlarnaPayment = ResolvedStandaloneKlarnaPayment;

export type KlarnaPaymentProps = PropsFromComponent<typeof KlarnaPayment>;

export const KlarnaPaymentWith = (extras: DynamicStandaloneExtras): typeof KlarnaPayment => {
    return function KlarnaPayment(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'KlarnaPayment');
        return <ResolvedStandaloneKlarnaPayment {...mergeProps({ options: { theme } }, props)} />;
    }
}