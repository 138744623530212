import { useClassName } from '@/styles/Checkbox';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { CheckboxInputComponent } from './Checkbox.Input';
import { CheckboxLabelComponent } from './Checkbox.Label';
import { mergeProps } from '@/utils/merge';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });
const Caption = GenericComponent({ as: 'span', styles: { key: 'caption' } });

export interface CheckboxProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

const CheckboxComponent: Component<CheckboxProps> = ({ children, ...props }) => {
  const className = useClassName('', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const Checkbox = Object.assign(CheckboxComponent, {
  Input: CheckboxInputComponent,
  Label: CheckboxLabelComponent,
  Caption: Caption,
});
