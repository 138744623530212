/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "background_background_colors_default": "",
  "background_background_size_default": "",
  "background_background_variant_default": "",
  "content_colors_default": "",
  "content_size_default": "",
  "content_variant_default": "",
  "content_title_colors_default": "",
  "content_title_size_default": "text-headline-lg",
  "content_title_variant_default": "",
  "content_description_colors_default": "",
  "content_description_size_default": "",
  "content_description_variant_default": "",
  "content_image_colors_default": "",
  "content_image_size_default": "",
  "content_image_variant_default": "",
  "colors_default": "bg-slate-200",
  "size_default": "py-10 px-20",
  "variant_default": "flex flex-col items-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CheckoutHeader");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;