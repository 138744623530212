/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "background_colors_default": "",
  "background_size_default": "",
  "background_variant_default": "",
  "title_colors_default": "",
  "title_size_default": "",
  "title_variant_default": "",
  "content_colors_default": "bg-slate-900",
  "content_size_default": "",
  "content_variant_default": "",
  "paymentGatewayLogo_colors_default": "",
  "paymentGatewayLogo_size_default": "",
  "paymentGatewayLogo_variant_default": "",
  "colors_default": "bg-slate-200",
  "size_default": "",
  "variant_default": "flex flex-col items-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CheckoutFooter");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;