/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckbox as ResolvedStandaloneCheckbox } from 'base/components/standalone/Checkbox';

export const Checkbox = ResolvedStandaloneCheckbox;

export type CheckboxProps = PropsFromComponent<typeof Checkbox>;

export const CheckboxWith = (extras: DynamicStandaloneExtras): typeof Checkbox => {
    return function Checkbox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Checkbox');
        return <ResolvedStandaloneCheckbox {...mergeProps({ options: { theme } }, props)} />;
    }
}