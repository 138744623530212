import { CheckoutBody } from '@/components/CheckoutBody';
import { CheckoutFooter } from '@/components/CheckoutFooter';
import { CheckoutHeader } from '@/components/CheckoutHeader';
import { Page404 } from '@/components/Page404';
import { useScheduled } from '@/hooks/useScheduled';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import getConfig from 'modules/config';
import { ReactNode } from 'react';
import { Checkout } from './Checkout';

export interface StandaloneCheckoutProps extends StandaloneComponentProps<typeof Checkout> {
  header?: typeof CheckoutHeader;
  body?: typeof CheckoutBody;
  footer?: typeof CheckoutFooter;
  closed?: { time?: string; copy?: React.ReactNode };
  disabledContent?: ReactNode;
}

export const StandaloneCheckout: StandaloneComponent<StandaloneCheckoutProps> = ({
  header,
  body,
  footer,
  closed,
  disabledContent,
  options,
  ...props
}) => {
  const { enabled } = getConfig('payments') ?? {};

  const { $form, ...$base } = options ?? {};

  const { time: closeTime, copy: closedCopy } = closed ?? {};

  const isSignUpClosed = useScheduled(closeTime);

  if (!enabled) {
    return disabledContent ? disabledContent : <Page404 />;
  }

  return (
    <Checkout {...$base} {...props}>
      <CheckoutHeader {...header} />
      <CheckoutBody {...body} />
      <CheckoutFooter {...footer} />

      {isSignUpClosed ? <Checkout.Closed>{closedCopy}</Checkout.Closed> : <Checkout.Form form={$form} />}
    </Checkout>
  );
};
