/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckout as ResolvedStandaloneCheckout } from 'base/components/standalone/Checkout';

export const Checkout = ResolvedStandaloneCheckout;

export type CheckoutProps = PropsFromComponent<typeof Checkout>;

export const CheckoutWith = (extras: DynamicStandaloneExtras): typeof Checkout => {
    return function Checkout(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Checkout');
        return <ResolvedStandaloneCheckout {...mergeProps({ options: { theme } }, props)} />;
    }
}