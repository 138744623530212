import { GenericComponentFactory } from 'base/components/GenericComponent';
import { useClassName } from '@/styles/CheckoutHeader';

const GenericComponent = GenericComponentFactory({ useClassName });

const Header = GenericComponent({ as: 'div', styles: { key: '' } });
const Background = GenericComponent({ as: 'picture', styles: { key: 'background' } });
const Content = GenericComponent({ as: 'div', styles: { key: 'content' } });
const ContentImage = GenericComponent({ as: 'picture', styles: { key: 'content_image' } });
const ContentTitle = GenericComponent({ as: 'h2', styles: { key: 'content_title' } });
const ContentDescription = GenericComponent({ as: 'p', styles: { key: 'content_description' } });

export const CheckoutHeader = Object.assign(Header, {
  Background: Background,
  Content: Object.assign(Content, { Image: ContentImage, Title: ContentTitle, Description: ContentDescription }),
});
