import { ClassNameProp, ComponentProps, ComponentWithRef } from '@/types/component';
import React from 'react';
import { useClassName } from '@/styles/Checkbox';
import { mergeProps } from '@/utils/merge';

export interface CheckboxInputProps extends Omit<ComponentProps<'input'>, 'size'> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const CheckboxInputComponent: ComponentWithRef<HTMLInputElement, CheckboxInputProps> = React.forwardRef(
  ({ size, ...props }, ref) => {
    const className = useClassName(
      'input',
      { ...props, size },
      {
        className: 'cursor-pointer',
      }
    );
    return <input {...{ ref }} {...mergeProps({ className }, props)} />;
  }
);
