import { useClassName } from '@/styles/Checkbox';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export interface CheckboxLabelProps extends ComponentProps<'label'> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const CheckboxLabelComponent: Component<CheckboxLabelProps> = ({ children, ...props }) => {
  const className = useClassName('label', props, { defaults: { size: 'default' } });

  return <label {...mergeProps({ className }, props)}>{children}</label>;
};
