import { ClassNameProvider } from '@/styles/CheckoutFooter';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { CheckoutFooter } from './CheckoutFooter';

export interface StandaloneCheckoutFooterProps extends StandaloneComponentProps<typeof CheckoutFooter> {
  title?: ReactNode;
  background?: ReactNode;
  content?: ReactNode;
  paymentGatewayLogo?: ReactNode;
}

export const StandaloneCheckoutFooter: StandaloneComponent<StandaloneCheckoutFooterProps> = ({
  title,
  background,
  content,
  paymentGatewayLogo,
  options,
  ...props
}) => {
  const { $background, $title, $content, $paymentGatewayLogo, ...$base } = options ?? {};

  return (
    <ClassNameProvider value={$base}>
      <CheckoutFooter {...$base} {...props}>
        {background && <CheckoutFooter.Background {...$background}>{background}</CheckoutFooter.Background>}
        {title && <CheckoutFooter.Title {...$title}>{title}</CheckoutFooter.Title>}
        <CheckoutFooter.Content {...$content}>{content}</CheckoutFooter.Content>
        {paymentGatewayLogo && (
          <CheckoutFooter.PaymentGatewayLogo {...$paymentGatewayLogo}>
            {paymentGatewayLogo}
          </CheckoutFooter.PaymentGatewayLogo>
        )}
      </CheckoutFooter>
    </ClassNameProvider>
  );
};
