import { ClassNameProvider } from '@/styles/Checkbox';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode, useId } from 'react';
import { Checkbox, CheckboxProps } from './Checkbox';
import { CheckboxInputProps } from './Checkbox.Input';
import { CheckboxLabelProps } from './Checkbox.Label';

export interface StandaloneCheckboxProps extends StandaloneComponentProps {
  label?: ReactNode;
  caption?: ReactNode;
  name?: CheckboxInputProps['name'];
  value?: CheckboxInputProps['value'];
  options?: CheckboxProps & {
    $input?: Omit<CheckboxInputProps, 'name' | 'value'>;
    $label?: CheckboxLabelProps;
    $caption?: typeof Checkbox.Caption;
  };
}

export const StandaloneCheckbox: StandaloneComponent<StandaloneCheckboxProps> = ({
  options,
  name,
  value,
  label,
  caption,
  ...props
}) => {
  const { $input: inputProps, $label: labelProps, $caption: captionProps, ...baseProps } = options ?? {};

  const id = useId();

  return (
    <ClassNameProvider value={baseProps}>
      <Checkbox {...baseProps} {...props}>
        <Checkbox.Input id={id} type="checkbox" {...{ name, value }} {...inputProps} />
        <Checkbox.Label htmlFor={id} {...labelProps}>
          {label}
        </Checkbox.Label>
        {caption && <Checkbox.Caption {...captionProps}>{caption}</Checkbox.Caption>}
      </Checkbox>
    </ClassNameProvider>
  );
};
