import { useCallback, useMemo } from 'react';
import logger from '@/utils/logger';
import isClient from '@/utils/isClient';

type PurchaseDataLayerPayload = {
  event: 'purchase';
  ecommerce: {
    transaction_id: string;
    currency: string;
    value?: number;
    items?: { item_name: string; item_id: string; price?: string | number }[];
  };
};

const useWindowDataLayer = () => {
  const push = useCallback((payload: PurchaseDataLayerPayload) => {
    try {
      if (isClient()) {
        window.dataLayer ??= [];
        window.dataLayer.push(payload);
      }
    } catch (e) {
      logger.error('Cannot push data to window.dataLayer');
    }
  }, []);

  return useMemo(() => ({ push }), [push]);
};

export default useWindowDataLayer;
